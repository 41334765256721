import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0062 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="みなし労働時間制について。概要やメリットやデメリットなどを解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.01.12</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        多くの企業が採用する「固定労働時間制」とは全く別の制度として「みなし労働時間制」があります。ただし、運用方法を誤ってしまうと違法な労務管理となる場合もあることから今回はみなし労働時間制の概要からメリット、デメリットを解説します。{' '}
      </p>
      <img src="/images/column/details/c0062.jpg" alt="キンクラコラム画像62" />
      <h1 id="toc1">みなし労働時間制の概要</h1>
      <p>
        みなし労働時間制には裁量労働制と事業場外みなし労働時間制があります。さらに、裁量労働制の中には専門業務型裁量労働制と企画業務型裁量労働制の2種類があります。それぞれ特徴があることから、個別に概要を後述します。
      </p>
      <h2 id="toc1-1">専門業務型裁量労働制</h2>
      <p>
        厚生労働省令で定める特定の業務（例えば有価証券市場における相場等の動向または有価証券の価値等の分析）が対象とできる制度で、業務の性質上、使用者からの具体的な業務遂行の手段や時間配分の指揮命令が馴染まない業種に限って適用することができます。言い換えると厚生労働省令で定められていない業務については適用させることができません。特徴として、
        <span>
          労働者自身で業務の時間配分等を決めることができ、あらかじめ労使協定により労働時間として算定される時間を「労働したとみなす」ことができる制度
        </span>
        です。具体的には労使協定で定めた時間を超えた場合や、反対に定めた時間に達しない場合であっても当該労使協定で定めた時間を働いたとみなすということです。なお、労使協定については不適切な運用となることを防ぐ意味で3年以内とすることが望ましいとされています。
      </p>
      <h2 id="toc1-2">企画業務型裁量労働制</h2>
      <p>
        事業活動の中枢にある労働者が企画、立案、調査、分析に関する業務において、
        <span>業務の進め方や時間配分を労働者の裁量に委ねる制度</span>
        です。専門業務型裁量労働制と同様に労働者自身の裁量によって、労働時間等の配分を決定できる制度ですが、導入するためには労使委員会の5分の4以上の多数の議決が必要となり、かつ、労使委員会の決議を労働基準監督署に届け出ることが必要です。
      </p>
      <h2 id="toc1-3">事業場外みなし労働時間制</h2>
      <p>
        事業場内での勤務よりも事業場外での勤務が多くを占める労働者（例えば営業職）に対しては、使用者からの指揮監督が及ばないといった問題があります。そこで、
        <span>労働時間の算定が困難な業務に対して採用される制度</span>
        です。ただし、「労働時間の算定が困難」な場合とは、事業場外にはいるものの、随時使用者の指示を受けながら労働している場合は適用対象とはなりません。本制度は原則として「所定労働時間」（働くことが求められている時間）労働したものとみなされますが、労働時間の一部について事業場外で業務に従事する場合は事業場内での業務は労働時間の管理ができることから、
        <span>
          事業場内での労働時間と事業場外での「当該業務の遂行に通常必要とされる時間」とを加えた時間労働したと扱われます。
        </span>
        また、事業場外で労働する時間が法定労働時間を超える場合は労働基準監督署へ届出が必要となります。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc2">みなし残業との違い</h1>
      <p>
        みなし残業とは、別名、固定残業と表現されることもありますが、考え方として、決められた時間（例えば20時間）数までの残業代を固定的に支払うことで、仮に決められた時間数を超えて労働させた場合（例えば23時間とする）追加で3時間分の残業代を支払わなければなりません。
        <span>
          言い換えると、20時間以内の残業であれば「追加」で残業代を払う義務はなく、仮に残業が全くなくても固定的に残業代を支払っていくということ
        </span>
        です。他方、みなし労働時間制については休憩、休日、深夜の適用は除外されておらず、例えば、休日割増20時間分としてみなし残業制を含めた労働契約を締結した場合、20時間以内の休日割増が発生しても既に20時間分までは支払っているため、追加で支払う義務はありません。他方、休憩、休日、深夜の適用のないみなし労働時間制は例えば休日や深夜割増が必要となる労務の提供を受けた場合、当該割増賃金の支払いが義務となります。
      </p>

      <h1 id="toc3">みなし労働時間制のメリット</h1>
      <p>
        有能な労働者にとって求人としての魅力がアップする点です。特に専門業務型裁量労働制や企画業務型裁量労働制の場合、業務の進め方に裁量があるとういうことは自身の有する能力を発揮しやすい環境にあると考えられます。また、企業としても
        <span>休日、深夜労働が発生しない限り残業代の支払い義務が生じない</span>
        点です。
      </p>

      <h1 id="toc4">みなし労働時間制のデメリット</h1>
      <p>
        特に企画業務型裁量労働制の場合、手続きが煩雑であることです。また、専門業務型裁量労働制であっても、適用可能な業種が限定的であることから、対象労働者の選定も必要となり、誤って適用させていた場合は問題となります。
      </p>

      <h1 id="toc5">最後に</h1>
      <p>
        みなし労働制を採用した場合であっても適切に労働時間の管理をすることが求められます。労働時間管理はタイムカードによる記録等が想定されますが、やむを得ない場合は適切な措置を講じた上で自己申告による記録も可能とされています。また、当該記録は3年間の保存義務があり、みなし労働時間制を採用した場合であっても例外ではありません。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0062
